import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from 'gatsby-link';
import {checkData} from "../../helper";

function Appcost({data}) {
    return (
        <div className="p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.tekgrip_body_txt}>
                            <h2>{checkData(data, 'app_cost_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <p>{checkData(data, 'app_cost_content')}</p>
                        <Link to={checkData(data, 'app_cost_btn_url')}
                              className="border_btn">{checkData(data, 'app_cost_btn_text')}</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Appcost;